import React, { SyntheticEvent, createRef } from "react";

import { FormContext } from "./form";
import { required, validateInput } from "./utilities/validations";
import { checkDisabledInput } from "./utilities/disables";
import { checkRequiredInput } from "./utilities/requires";
import { checkValueInput } from "./utilities/values";

import Tooltip from "@adeccoux/tag-ds/tooltip";
import { toLiteral } from "../../../helper/locale-utils";
import { IFormMultilanguageOption } from "./multilanguage/multi-language-service";

export interface IProps {
  id?: string;
  name?: string;
  type?: string;
  className?: string;
  value?: string | string[];
  disabled?: boolean;
  disableRelated?: string[];
  label?: string | Function;
  errors?: string[];
  validations?: Function[];
  validateOnChange?: boolean;
  readOnly?: boolean;
  loading?: boolean;
  placeholder?: string;
  onChange?: Function;
  autoComplete?: string;
  tooltip?: string;
  hiddeRequired?: boolean; // For hidding * required mark in special casses like radio inputs with global label
  [others: string]: any;
  //previous line avoids having to write specific component properties
  //rows?: number; //textarea only
  //cols?: number; //textarea only
}

export interface IState {
  name?: string;
  errors?: string[];
  isValid?: boolean;
  value?: string | string[];
  validateOnChange?: boolean;
  parentFormOnChange?: Function;
  readOnly?: boolean;
  loading?: boolean;
  disabled?: boolean;
  disableRelated?: string | string[];
  requireByRelated?: boolean;
  requireRelated?: string | string[];
  availableLanguages?: IFormMultilanguageOption[];
  currentLanguage?: IFormMultilanguageOption;
  [others: string]: any;
}

class BaseInput extends React.Component<IProps, IState> {
  type: string = "";
  _isMounted: boolean = false;
  value: string | string[] | undefined = "";
  validationClass: string | undefined = "";
  loadingClass: string | undefined = "";
  inputRef: any = createRef();
  tooltipRef = createRef<HTMLSpanElement>();

  constructor(props: any) {
    super(props);

    this.state = {
      name: this.props.name,
      errors: this.props.errors,
      isValid: undefined,
      value: this.props.value !== null && this.props.value !== undefined ? this.props.value : "",
      validateOnChange: this.props.validateOnChange === false ? this.props.validateOnChange : true,
      readOnly: this.props.readOnly,
      loading: this.props.loading,
      disabled: this.props.disabled,
      disableRelated: this.props.disableRelated,
      requireByRelated: false,
      requireRelated: this.props.requireRelated,
      children: this.props.children,
      options: this.props.options,
    };
    this._isMounted = false;

    this.type = "";
    this.change = this.change.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.context && typeof this.context.addInputToContext === "function") {
      this.context.addInputToContext(this);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this._isMounted) {
      if (this.props.name !== prevProps.name) {
        this.setState({ name: this.props.name });
      }
      if (this.props.value !== prevProps.value) {
        this.setState({ value: this.props.value });
      }
      if (this.props.readOnly !== prevProps.readOnly) {
        this.setState({ readOnly: this.props.readOnly });
      }
      if (this.props.loading !== prevProps.loading) {
        this.setState({ loading: this.props.loading });
      }
      if (this.props.tooltip !== prevProps.tooltip) {
        this.setState({ tooltip: this.props.tooltip });
      }
      if (this.props.disabled !== prevProps.disabled) {
        this.setState({ disabled: this.props.disabled });
      }
      if (this.props.disableRelated !== prevProps.disableRelated) {
        this.setState({ disableRelated: this.props.disableRelated });
      }
      if (this.props.requireRelated !== prevProps.requireRelated) {
        this.setState({ requireRelated: this.props.requireRelated });
      }

      if (this.props.children !== prevProps.children) {
        this.setState({ children: this.props.children });
      }
      if (this.props.options !== prevProps.options) {
        this.setState({ options: this.props.options });
      }
      try {
        if (JSON.stringify(prevState) !== JSON.stringify(this.state)) {
          //just for you sonar =),  to avoid wrong code smell for prevState, needed for checkbox input
        }
      } catch (error) {}
    }
  }

  onChange = async (e: SyntheticEvent) => {
    const target: any = e.target as HTMLInputElement;
    let newValue = target.value;
    if (this.type === "SelectInput" && this.props.multiple) {
      const options = target.options;
      newValue = [];
      for (let i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          newValue.push(options[i].value);
        }
      }
    }
    e.persist();
    this.processChange(newValue, e);
  };

  change = (value: string | string[], forceUserEvent: boolean | undefined = false) => {
    const isFakeEvent = forceUserEvent ? false : true;
    const fakeEvent = {
      target: { ...this.inputRef, value: value, fakeEvent: isFakeEvent },
    };

    this.processChange(value, fakeEvent);
  };

  //this is to be overrided by inheritance inputs and set rules to decide whether the value can be set or not: ie In select dropdowns only if the value exist as option
  valueCanBeSet = (value: string | string[], e: SyntheticEvent | any = undefined) => {
    return true;
  };
  // To override it
  changeHiddenInput = (value: string | string[]) => {};

  processChange = (value: string | string[], e: SyntheticEvent | any = undefined) => {
    const self = this;
    if (this.valueCanBeSet(value, e)) {
      this.setState({ value: value }, async () => {
        if (this.state.validateOnChange && !e?.target?.fakeEvent) {
          validateInput(this, this.context);
        }

        if (e?.target?.current) {
          //for fake inputs like select filter and wysiwyg, the event is not an input change, but a click on something
          //so it doesn't contain the real value that was set.
          //here we add a new event property to the event, to store the real value
          e.target.inputValue = self?.state?.value;
        }

        if (e && this.props.onChange) {
          this.props.onChange(e);
        }

        if (e && !e?.target?.fakeEvent && typeof this.state.parentFormOnChange === "function") {
          this.state.parentFormOnChange(e);
        }
        // Related fields - functions to put required/disabled/value in other fields
        if (this.props.requireRelated) checkRequiredInput(this, this.context);
        if (this.props.disableRelated) checkDisabledInput(this, this.context);
        if (this.props.valueRelated) checkValueInput(this, this.context);
        if (this.type === "SelectInput") this.changeHiddenInput(value);
      });
    }
  };

  renderErrorMessage = () => {
    if (this.state.errors) {
      return <div className="input-error">{this.state.errors[0]}</div>;
    } else if (this.props.helperText) {
      return (
        <div className="input-helper-text">
          <span className="material-icons info-icon mr1" style={{ color: "#1c304b" }}>
            info
          </span>
          {this.props.helperText}
        </div>
      );
    }
  };

  processCSSClasses = () => {
    this.value = this.state.value ? this.state.value : "";
    this.validationClass = "";
    if (typeof this.state.isValid !== "undefined" && this.state.isValid !== null) {
      if (!this.state.isValid || this.value !== "") {
        // Show only when error or is valid but not empty
        this.validationClass = this.state.isValid ? "valid" : "error";
      }
    }
    if (this.props.visible !== false && ((this.props.validations && this.props.validations.indexOf(required) !== -1) || this.state.requireByRelated)) {
      this.validationClass += " validation-required";
    }
    this.loadingClass = this.state && this.state.loading ? "loading" : "";
    if (this.state.loading) {
      this.loadingClass = "loading";
    }
  };

  renderTooltip(parentRef?: any) {
    if (this.props.tooltip) {
      return (
        <Tooltip parentRef={parentRef} renderAsPortal>
          {this.props.tooltip}
        </Tooltip>
      );
    }
  }

  renderTooltipIcon() {
    return this.props.tooltip ? (
      <span className="material-icons info-icon ml1" ref={this.tooltipRef}>
        info
        {this.renderTooltip(this.tooltipRef)}
      </span>
    ) : null;
  }

  renderInputLabel() {
    const isLabelNextToInput = this.type === "CheckboxInput" || this.type === "RadioInput" || this.type === "SwitchInput";
    const labelLiteral = typeof this.props.label === "string" ? toLiteral({ id: this.props.label, strictLanguage: true }) || toLiteral({ id: this.props.label }) : "";
    let labelClassName = isLabelNextToInput ? "" : "caption";
    labelClassName = this.state?.isValid === false && isLabelNextToInput ? labelClassName + " error" : labelClassName;
    if (this.props.label) {
      return (
        <label className={labelClassName} htmlFor={this.props.id}>
          {this.validationClass && this.validationClass.includes("validation-required") && !this.props.hiddeRequired && <small className="required">*</small>}
          <span className="label-text">{typeof this.props.label === "function" ? this.props.label() : labelLiteral}</span>
          {this.renderTooltipIcon()}
        </label>
      );
    }
  }

  render() {
    return (
      <></> //this get overrided by inheritances
    );
  }
}

BaseInput.contextType = FormContext;
export default BaseInput;
