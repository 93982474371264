import _ from "lodash";
import { v4 as uuid } from "uuid";
import moment from "moment";
import store from "../../redux/store";

import { isTrue } from "../../helper/object-helper";
import { toLiteral } from "../../helper/locale-utils";
import { getRoutePath } from "../../utilities/navigation";
import { getValueDate, parseToDate } from "../../helper/form-ui";
import { getFeatureConfiguration, getFeatureConfigurationValues } from "../../helper/feature-configuration-helper";

import { replaceContactInfo } from "../shared/forms/utilities/automatic-contact-utils";
import { cleanHTMLBeforeSend, clearAllInsightsErrors } from "../shared/forms/utilities/errors-wsyiwyg";

import { JobApplyMethod } from "../../types";
import { CONTACT_PROVIDER_OTHER, IProviderInfo, PeriodType } from "../../constants";
import {
  INFO_JD_PARAM,
  JD_DEFAULT_BOARD_ID,
  JOVEO_TITLE_CART,
  OPTIONSCONTACTINFO,
  OVERWRITE_APPLY,
  STATUS_WEB_JD_ID,
  STATUS_WEB_JD_MAPPED,
  VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP,
} from "./candidate-sourcing-constants";
import { generateStatus, IScheduleJD } from "../../helper/candidate-sourcing-helper";
import { getBranchId } from "../../helper/tenant-helper";
import { isAdminOrSuperAdmin } from "../../helper/role-helper";
import { capitalize } from "../../helper/string-helper";

import industryJson from "./tenantsData/industriesInfoJobs/industries.json";
import subindustryJson from "./tenantsData/industriesInfoJobs/subindustries.json";
import professionJson from "./tenantsData/professionHelpLavoro/profession.json";
import subprofessionJson from "./tenantsData/professionHelpLavoro/subprofession.json";
import { getDateFormatFromTenantOrBrowser } from "../../helper/date-format-helper";

export function getJobDescriptionURL(id: any) {
  return `${getRoutePath("JOB_DESCRIPTION").replace(":id", id)}`;
}

export function getJobDescriptionTemplateURL(id: any) {
  return getRoutePath("JOB_DESCRIPTION_TEMPLATE").replace(":id", id);
}
export function getJobDescriptionLocalTemplateURL(id: any) {
  return getRoutePath("JOB_DESCRIPTION_LOCAL_TEMPLATE").replace(":id", id);
}

export function getPrintJobDescriptionURL(id: any) {
  return getRoutePath("JOB_DESCRIPTION_PRINTABLE").replace(":id", id);
}

export function getPdfJobDescriptionURL(id: any) {
  return getRoutePath("JOB_DESCRIPTION_PDF_GENERATOR").replace(":jobDescriptionId", id);
}

export function jobListingURL() {
  return getRoutePath("JOB_LISTING");
}

export function jobPostingListingURL() {
  return getRoutePath("JOB_POSTING_LISTING");
}

export function jobListingTemplateURL() {
  return getRoutePath("JOB_DESCRIPTION_TEMPLATES");
}

export const sortData = (data: any, value: any, direction: any) => {
  return data.sort((a: any, b: any) => {
    let nameA = getJobDescriptionValueFromColumnKey(value, a),
      nameB = getJobDescriptionValueFromColumnKey(value, b);
    if (direction === "asc") {
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
    } else {
      if (nameA > nameB) return -1;
      if (nameA < nameB) return 1;
    }
    return 0;
  });
};

const _getValueFromKey = (key: string, item: any) => {
  if (typeof item[key] !== "object") {
    return item[key];
  } else {
    return "";
  }
};

const updateFilterOptions = (allResults: any[], columns: any, getValueFromKey?: Function, isJobFilterOptions: boolean = false) => {
  allResults.forEach((element: any) => {
    //gets the available options for each result and updates the filterOptions of each column
    columns.forEach((col: any) => {
      let filterOptions: any = isJobFilterOptions ? col.jobFilterOptions : col.filterOptions;
      const elementValueForThisColumn = getValueFromKey ? getValueFromKey(col.columnKey, element) : _getValueFromKey(col.columnKey, element); //get the value of the element for this column
      if (elementValueForThisColumn) {
        //if there is a value
        let optionExistInArray = null;
        if (typeof elementValueForThisColumn === "object") {
          optionExistInArray = filterOptions.findIndex((filterOption: any) => filterOption.value === elementValueForThisColumn.value) !== -1; //and it¡'s not yet in the array filter options
        } else {
          optionExistInArray = filterOptions.indexOf(elementValueForThisColumn) !== -1; //and it¡'s not yet in the array filter options
        }

        if (!optionExistInArray) {
          if (typeof elementValueForThisColumn === "string" || (typeof elementValueForThisColumn === "object" && col.type === "object")) {
            filterOptions.push(elementValueForThisColumn); //add it to the array
          }
        }

        if (isJobFilterOptions) {
          col.jobFilterOptions = filterOptions;
        } else {
          col.filterOptions = filterOptions;
        }
      }
    });
  });
};

export const processFilterOptions = (allResults: any[], columns: any, getValueFromKey?: Function) => {
  updateFilterOptions(allResults, columns, getValueFromKey);
};

export const jobFilterOptions = (allResults: any[], columns: any, getValueFromKey?: Function) => {
  updateFilterOptions(allResults, columns, getValueFromKey, true);
};

export function getJobDescriptionValueFromColumnKey(key: string, item: any, format?: string) {
  if (item) {
    switch (key) {
      case "status":
        const status = generateStatus(item.status ? item.status : "", item.jobDescriptionSchedules);
        if (status) {
          return capitalize(status);
        }
        break;
      case "client":
        return item.clientName;
      case "createdBy":
        return { label: item.createdBy?.name, value: item.createdBy?.id };
      case "createdOn":
        const parsedDate = moment(item?.createdAt).format(format || getDateFormatFromTenantOrBrowser());
        return parsedDate;
      case "vacancyReferenceNumber":
        return item.vacancyReferenceNumber;
      case "startDate":
        return item.startDate;
      case "endDate":
        return item.endDate;
      case "actions":
        return null;
      case "descriptionDisplayLanguage":
        return item.descriptionDisplayLanguage;
      case "jobPosts":
        return false;
      default:
        if (typeof item[key] !== "object") {
          return item[key];
        } else {
          return "";
        }
    }
  }
}

export function getJobDescriptionTemplateValueFromColumnKey(key: string, item: any) {
  if (item) {
    switch (key) {
      case "title":
        return item.title;
      case "categoryId":
        return item.categoryId;
      case "client":
        return item.clientName;
      case "createdByUser":
        return { label: item.createdByUser?.name, value: item.createdByUser?.id };
      case "createdOn":
        return item.createdAt;
      case "displayLanguage":
        return item.displayLanguage?.[0];
      case "sectionsReorder":
      case "sectionsAdding":
      case "predefinedNaming":
        return isTrue(item[key]) ? "Yes" : "No";
      case "local":
        return isTrue(item[key]) ? "No" : "Yes";
      case "actions":
        return null;
      default:
        if (typeof item[key] !== "object") {
          return item[key];
        } else {
          return "";
        }
    }
  }
}

export function getJobDescriptionScheduleValueFromColumnKey(key: string, item: any, format?: string) {
  if (item) {
    switch (key) {
      case "statusId":
        let statusElement = item?.statusId;
        return getStatusNameByStatusId(statusElement, false);
      case "name":
        return item.boardName || item.name; // Listing posting - Accordion schedule
      case "vacancyReferenceNumber":
        return item.dataVacancyReferenceNumber;
      case "externalId":
        return item.jobDescriptionExternalId;
      case "internalJobTitle":
        return item.dataInternalJobTitle;
      case "externalJobTitle":
        return item.dataExternalJobTitle;
      case "clientName":
        return item.dataClientName;
      case "cost":
        // Return budgetCup for Joveo, the rest use the cost
        if (item.boardName === JOVEO_TITLE_CART || item.name === JOVEO_TITLE_CART) {
          return item.budgetCup || item.boardCost;
        } else {
          return item.boardCost;
        }
      case "startDate":
        const startDate = moment(item?.startDate).format(format || getDateFormatFromTenantOrBrowser());
        return startDate;
      case "endDate":
        return item.endDate;
      case "createdAt":
        const parsedDate = moment(item?.createdAt).format(getDateFormatFromTenantOrBrowser());
        return parsedDate;
      case "createdBy":
        return { label: item.createdBy, value: item.createdById };
      case "endingSoon":
        if (item?.statusId === STATUS_WEB_JD_ID.PUBLISHED) {
          return endingSoonPosting(item.endDate);
        } else {
          return false;
        }
      case "actions":
        return null;
      case "branchId":
        return item?.branchName;
      default:
        if (typeof item[key] !== "object") {
          return item[key];
        } else {
          return "";
        }
    }
  }
}

const getValueBoolean = (value: any) => {
  return value === "true" || value === true ? true : false;
};

export const getValueCommaSeparated = (value: any) => {
  if (value && typeof value === "string") {
    const values = value.split(",");
    return values.map((_value) => _value.trim()).filter((_value) => _value);
  } else if (value && typeof value === "object" && Array.isArray(value)) {
    return value;
  }
};

export const hasValue = (value: any) => {
  return value !== null && typeof value !== "undefined" && value !== "";
};

export const checkIfCheckboxValueExists = (values: any, key: string, value: string) => {
  // When only ONE is checked
  const valueIsCheckbox = values[key] && values[key] === value;

  // When there are two
  let valueContainsCheckbox = false;
  try {
    valueContainsCheckbox = typeof values[key] === "object" && values[key].indexOf(value) !== -1;
  } catch (error) {}

  if (valueIsCheckbox || valueContainsCheckbox) {
    return true;
  } else {
    return false;
  }
};

const getPostValues = (value: any, values: any, postType: string) => {
  const currentPostValue2 = values["postType"];
  if (value) {
    return currentPostValue2 ? [...currentPostValue2, postType] : [postType];
  } else {
    return currentPostValue2;
  }
};

export async function mapValuesForBackend(serializedForm: any, schedules: IScheduleJD[]) {
  let schedulesToSave = [] as IScheduleJD[];
  let multiSchedulesToSave = [] as IScheduleJD[];
  const defaultSchedules = {
    jobDescriptionInternalPosting: {} as IScheduleJD,
    jobDescriptionInternalApplyFlowPosting: {} as IScheduleJD,
    jobDescriptionExternalPosting: {} as IScheduleJD,
    jobDescriptionVonqPosting: {} as IScheduleJD,
  };
  const multiSchedules = {
    jobDescriptionInternalPosting: [] as IScheduleJD[],
    jobDescriptionInternalApplyFlowPosting: [] as IScheduleJD[],
    jobDescriptionExternalPosting: [] as IScheduleJD[],
    jobDescriptionVonqPosting: [] as IScheduleJD[],
  };

  const contactInfo = {
    name: serializedForm?.dataContactName || "",
    email: serializedForm?.dataContactEmail || "",
    phone: serializedForm?.dataContactPhone || "",
    reference: serializedForm?.dataVacancyReferenceNumber || "",
  };

  Object.keys(serializedForm).forEach((key) => {
    let value = serializedForm[key];

    switch (key) {
      case "dataKeywords":
      case "dataSynonyms":
      case "descriptionDisplayLanguage":
        value = getValueCommaSeparated(value);
        break;
      case "dataNationwide":
      case "dataConfidential":
      case "dataSalaryPubExternally":
      case "postMultiposting":
        value = getValueBoolean(value);
        break;
      case "createdAt":
      case "updatedAt":
      case "dataEstimatedStartDate":
      case "dataEstimatedEndDate":
      case "postTargetDeadline":
      case "postStartDate":
      case "postEndDate":
        value = getValueDate(value);
        break;
      case "dataApplyURL":
      case "dataClientNumber":
      case "dataCVMandatory":
      case "dataHiringType":
      case "dataHomeOffice":
      case "dataProdurementOrderGFLO":
      case "dataPublishGFLO":
      case "dataCollectiveAgreement":
      case "dataCollectiveAgreementName":
      case "dataSkillName":
      case "dataSkillLevel":
      case "dataEducation":
      case "dataPlacementType":
      case "dataSoftSkils":
      case "dataApplicationMethod":
      case "dataInfoJobsSubIndustry":
        if (!serializedForm.dataCustomFields) serializedForm.dataCustomFields = [];
        serializedForm.dataCustomFields.push({ key, value });
        delete serializedForm.key;
        break;
      case "dataExclusive":
        if (!serializedForm.dataCustomFields) serializedForm.dataCustomFields = [];
        value = getValueBoolean(value);
        serializedForm.dataCustomFields.push({ key, value });
        delete serializedForm.key;
        break;
      case "dataContractType_label":
      case "dataFunction_label":
      case "dataIndustry_label":
      case "dataEducationLevel_label":
      case "dataYearsOfExperience_label":
      case "dataManagementExperience_label":
      case "dataPayRatePeriod_label":
      case "dataEmploymentType_label":
      case "dataInfoJobsSubIndustry_label":
        if (!serializedForm.dataPlainTextFields) serializedForm.dataPlainTextFields = {};
        serializedForm.dataPlainTextFields[key] = value;
        delete serializedForm.key;
        break;
      default:
        break;
    }

    if (key === "jobDescriptionInternalPosting" || key === "jobDescriptionInternalApplyFlowPosting" || key === "jobDescriptionExternalPosting" || key === "jobDescriptionVonqPosting") {
      const boardId = getUuidFromDefaultBoard(key);
      const newSchedule = generateDefaultSchedule(boardId);
      if (value.id) newSchedule.id = value.id;
      newSchedule.startDate = getValueDate(value.startDate, true);
      newSchedule.endDate = getValueDate(value.endDate, true);
      defaultSchedules[key] = newSchedule;
      value.schedules?.forEach((schedule: IScheduleJD) => {
        const newMultiSchedule = generateDefaultSchedule(boardId);
        newMultiSchedule.id = schedule.id;
        newMultiSchedule.startDate = getValueDate(schedule.startDate, true);
        newMultiSchedule.endDate = getValueDate(schedule.endDate, true);
        multiSchedules[key].push(newMultiSchedule);
      });
      delete serializedForm[key];
    }

    if (key?.indexOf("descriptionSections") !== -1) {
      if (value && value.length) {
        const formatedValue: any = [];
        value.forEach((element: any) => {
          // Check title
          if (!element.title) {
            element.title = element.titleValue;
          }
          // Formated value text
          let elementValue = element.value;
          if (elementValue) {
            elementValue = clearAllInsightsErrors(elementValue);
            elementValue = replaceContactInfo(elementValue, contactInfo);
            element.value = elementValue;
          }
          formatedValue.push(element);
        });
        value = formatedValue;
      }

      serializedForm[key] = value;
    }

    if (hasValue(value)) {
      serializedForm[key] = value;
    } else {
      value = null;
      serializedForm[key] = value;
    }
  });

  // Check checkbox check and object generated
  const optionsToCheck = [
    { name: "postToAdecco", schedules: "jobDescriptionInternalPosting" },
    { name: "postToApplyFlow", schedules: "jobDescriptionInternalApplyFlowPosting" },
    { name: "postExternally", schedules: "jobDescriptionExternalPosting" },
    { name: "postVonq", schedules: "jobDescriptionVonqPosting" },
  ];
  optionsToCheck.forEach((option: any) => {
    serializedForm[option.name] = checkIfCheckboxValueExists(serializedForm, "postType", option.name);
    if (serializedForm[option.name] && Object.keys((defaultSchedules as any)[option.schedules]).length !== 0) {
      schedulesToSave.push((defaultSchedules as any)[option.schedules]);
      multiSchedulesToSave = multiSchedulesToSave.concat((multiSchedules as any)[option.schedules]);
    }
  });
  const schedulesMultiposting = serializedForm["postMultiposting"] ? schedules : [];
  serializedForm.jobDescriptionSchedules = unionJobDescriptionSchedules(schedulesMultiposting, schedulesToSave, multiSchedulesToSave);
  return serializedForm;
}

export function mapBackendvaluesForForm(values: any) {
  const tenantAlias = store?.getState()?.tenant?.selectedTenantAlias;
  let findPostingOption = false;
  let findPosting: any = {
    jobDescriptionInternalPosting: false,
    jobDescriptionInternalApplyFlowPosting: false,
    jobDescriptionExternalPosting: false,
    jobDescriptionVonqPosting: false,
  };
  let _values: any = {
    jobDescriptionInternalPosting: {},
    jobDescriptionInternalApplyFlowPosting: {},
    jobDescriptionExternalPosting: {},
    jobDescriptionVonqPosting: {},
  };

  Object.keys(values).forEach((key) => {
    let value = values[key];
    if (value !== null && typeof value !== "undefined" && value !== "") {
      switch (key) {
        case "createdAt":
        case "updatedAt":
        case "postTargetDeadline":
        case "dataEstimatedStartDate":
        case "dataEstimatedEndDate":
          _values[key] = parseToDate(value);
          break;
        case "descriptionDisplayLanguage":
          _values[key] = value?.length > 0 ? value[0] : "";
          break;
        case "postToAdecco":
        case "postExternally":
        case "postVonq":
          _values["postType"] = getPostValues(value, _values, key);
          if (_values["postType"]?.length > 0) findPostingOption = true;
          break;
        case "postMultiposting":
          _values[key] = value;
          if (_values[key]) findPostingOption = true;
          break;
        case "dataCustomFields":
          if (value && value.length > 0) {
            value.forEach((customField: { key: string; value: string }) => {
              _values[customField.key] = customField.value;
            });
          }
          break;
        case "jobDescriptionSchedules":
          const schedules: any[] = [];
          if (value && value.length > 0) {
            value.forEach((schedule: any) => {
              if (
                schedule.boardId === JD_DEFAULT_BOARD_ID.INTERNAL ||
                schedule.boardId === JD_DEFAULT_BOARD_ID.INTERNALAPPLYFLOW ||
                schedule.boardId === JD_DEFAULT_BOARD_ID.EXTERNAL ||
                schedule.boardId === JD_DEFAULT_BOARD_ID.VONQ
              ) {
                let postingOption;
                if (schedule.boardId === JD_DEFAULT_BOARD_ID.INTERNAL) {
                  postingOption = "jobDescriptionInternalPosting";
                } else if (schedule.boardId === JD_DEFAULT_BOARD_ID.INTERNALAPPLYFLOW) {
                  postingOption = "jobDescriptionInternalApplyFlowPosting";
                } else if (schedule.boardId === JD_DEFAULT_BOARD_ID.EXTERNAL) {
                  postingOption = "jobDescriptionExternalPosting";
                } else {
                  postingOption = "jobDescriptionVonqPosting";
                }
                if (!findPosting[postingOption]) {
                  findPosting[postingOption] = true;
                  _values[postingOption] = {
                    id: schedule.id,
                    statusId: schedule.statusId,
                    startDate: parseToDate(schedule?.startDate),
                    endDate: parseToDate(schedule?.endDate),
                    schedules: [],
                  };
                } else {
                  _values[postingOption].schedules.push({
                    id: schedule.id,
                    statusId: schedule.statusId,
                    startDate: parseToDate(schedule?.startDate),
                    endDate: parseToDate(schedule?.endDate),
                  });
                }
              } else {
                schedule.startDate = parseToDate(schedule?.startDate);
                schedule.endDate = parseToDate(schedule?.endDate);
                schedule.name = schedule?.board?.name || "";
                schedule.description = schedule?.board?.description || "";
                schedule.cost = schedule?.board?.cost || 0;
                delete schedule.jobDescriptionVersionId;
                delete schedule.status;
                delete schedule.board;
                schedules.push(schedule);
              }
            });
          }
          _values[key] = schedules;
          break;
        case "descriptionClientJobDescription":
          _values[key] = cleanHTMLBeforeSend(value);
          break;
        default:
          _values[key] = value;
          break;
      }
    } else {
      if (key === "dataClientOrderType") {
        _values["dataClientOrderType"] = "clientOrder";
      } else if (key === "dataPayRatePeriod") {
        // Set default to PERDAY except ProServ and Adecco Germany
        switch (tenantAlias) {
          case "PLRwT3Nabcg7g6LMzxZx":
          case "EtvMBWOQaXheIs9U0UIQ":
          case "h1328fdMTRUXxVYWcM8A":
          case "9Y6Jg4iHQ2mq1EdXpnDL":
          case "9tjp3HBCpYtaqgLVH7cH":
          case "cuY9TEfwuQwbECCnnfN5":
          case "cAukrpzneH97AXbTsW6q":
            _values["dataPayRatePeriod"] = "PERMONTH";
            break;
          default:
            _values["dataPayRatePeriod"] = "PERDAY";
            break;
        }
      } else {
        value = null;
        _values[key] = value;
      }
    }
  });

  if (!findPostingOption) {
    _values.postToAdecco = true;
    _values.postType = ["postToAdecco"];
  }

  // Provisional fix - force enable if have schedules
  if (_values?.jobDescriptionSchedules?.length > 0) {
    _values.postMultiposting = true;
  }

  return _values;
}
/* ******************************************** END SERIALIZACION ************************************ */

export const getFileStream = (value: any) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };
    reader.addEventListener(
      "load",
      function () {
        resolve(reader.result);
      },
      false
    );
    if (value) {
      if (typeof value === "string") {
        resolve(value);
      } else {
        reader.readAsDataURL(value);
      }
    } else {
      resolve(null);
    }
  });
};

export function generateContactInfoFromValues(values: any) {
  const contactInfo = {} as any;
  for (const optionContactInfo of OPTIONSCONTACTINFO) {
    contactInfo[optionContactInfo.label] = values && values[optionContactInfo.value] ? values[optionContactInfo.value] : "";
  }
  return contactInfo;
}
export function setContactProvider(values: any, provider: string, data: IProviderInfo) {
  if (values) {
    values.dataContactProvider = provider || CONTACT_PROVIDER_OTHER;
    for (const optionContactInfo of OPTIONSCONTACTINFO) {
      // Condition to avoid modify not contact fields
      if (optionContactInfo.set) values[optionContactInfo.value] = (data as any)[optionContactInfo.label] || "";
    }
  }
  return values;
}

export function formatedToUrl(text: string) {
  // eslint-disable-next-line no-useless-escape
  const replaceStrangeChars = /[.,:;¨"'´^ç*+\-_\\|\/¡!¿?@·#$~%&¬(){}[\]=ºª]/g;
  let _text = text || "";
  _text = _text.trim().toLowerCase();
  _text = _text.replace(replaceStrangeChars, "").replace(/ /g, "-");
  return _text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function generateURLExternal(item: any, props: any) {
  let url = "#";
  if (props?.jobsUrl) {
    const tenantId = store?.getState()?.tenant?.selectedTenant?.id;
    const titleFormated = formatedToUrl(item?.dataExternalJobTitle);
    url = props.jobsUrl; //'https://www.badenochandclark.com/de-ch/job' || https://cd-adecco-it.uat.cms.adecco.com/offerte-lavoro
    switch (tenantId) {
      /* Adecco IT */
      case 115:
        url += `/k-${titleFormated}`;
        break;
      /* AD CH - 137 */
      /* B&C - 178 */
      /* Germany */
      case 137:
      case 178:
      case 109:
      case 275:
      case 276:
      case 277:
      default:
        if (item?.dataCity && item?.dataExternalJobTitle) {
          const cityName = formatedToUrl(item.dataCity);
          url += `/${cityName}/${titleFormated}/${item.dataVacancyReferenceNumber}`;
        }
        break;
    }
  }
  return url;
}

/**
 ** PUBLISH - normal status (online)
 ** UPDATING - previous status: Publish/NotUpdated, request to update in publisher and waiting response (Vonq)
 ** NOTUPDATED - previous status: Updating, request to update but failed to update in publisher (Vonq)
 ** DELETING - previous status: Publish*, request to delete in publisher and waiting response (Vonq)
 ** NOTDELETED - previous status: Deleting, request to delete but failed to remove in publisher (Vonq)
 */
export function hasAnyJobSchedulePublished(jobSchedules: any[]): boolean {
  const statuses = [STATUS_WEB_JD_ID.PUBLISHED, STATUS_WEB_JD_ID.UPDATING, STATUS_WEB_JD_ID.NOTUPDATED];
  const findJobSchedule = jobSchedules?.find((jobSchedule) => {
    return statuses.includes(jobSchedule?.statusId);
  });
  return !!findJobSchedule; // Return true or false;
}
/**
 ** PENDING - previous status: Draft, request to post to publish in publisher and waiting response (Vonq)
 ** FAILED - previous status: Pending, request to publish but error in the publisher (Vonq)
 ** ARCHIVED - removed in publisher side
 */
export function hasJobScheduleEnded(jobSchedules: any[]): boolean {
  const statuses = [STATUS_WEB_JD_ID.PENDING, STATUS_WEB_JD_ID.FAILED, STATUS_WEB_JD_ID.ARCHIVED, STATUS_WEB_JD_ID.DELETING, STATUS_WEB_JD_ID.NOTDELETED];
  const findJobSchedule = jobSchedules?.find((jobSchedule) => {
    return statuses.includes(jobSchedule?.statusId);
  });
  return !!findJobSchedule; // Return true or false;
}

export function hasAnyJobScheduleScheduled(jobSchedules: any[]): boolean {
  const findJobSchedule = jobSchedules?.find((jobSchedule) => {
    return jobSchedule.statusId === STATUS_WEB_JD_ID.SCHEDULE;
  });
  return !!findJobSchedule; // Return true or false;
}
export function hasAnyJobScheduleInStatus(jobSchedules: any[], statusId: number): boolean {
  const findJobSchedule = jobSchedules?.find((jobSchedule) => {
    return jobSchedule.statusId === statusId; // USE THIS: STATUS_WEB_JD_ID.PUBLISHED
  });
  return !!findJobSchedule; // Return true or false;
}

export function isAllInDraftJobScheduleInJobDescription(jobSchedules: any[], fromSchedule = false): boolean {
  const findJobSchedule = jobSchedules?.find((jobSchedule) => {
    return isPublishJobSchedule(jobSchedule.statusId, fromSchedule);
  });
  return !findJobSchedule;
}
export function isAllInArchiveJobScheduleInJobDescription(jobSchedules: any[]): boolean {
  const statuses = [STATUS_WEB_JD_ID.FAILED, STATUS_WEB_JD_ID.ARCHIVED];
  // Avoid when there aren't schedules
  let allInArchive = jobSchedules?.length > 0 ? true : false;
  jobSchedules?.forEach((jobSchedule) => {
    const isArchive = statuses.includes(jobSchedule?.statusId);
    if (!isArchive) allInArchive = false;
  });
  return allInArchive;
}

export function isAllowUnpublish(statusId: number = 0) {
  return statusId !== STATUS_WEB_JD_ID.FAILED && statusId !== STATUS_WEB_JD_ID.ARCHIVED && statusId !== STATUS_WEB_JD_ID.PAUSED;
}
export function isPublishJobSchedule(statusId: number = 0, fromSchedule = false): boolean {
  if (fromSchedule) return statusId >= STATUS_WEB_JD_ID.SCHEDULE;
  else return statusId > STATUS_WEB_JD_ID.SCHEDULE;
}
export function isInPublishJobSchedule(statusId: number = 0): boolean {
  return statusId === STATUS_WEB_JD_ID.PUBLISHED;
}
export function isDraftJobSchedule(statusId: number = 0): boolean {
  return statusId === STATUS_WEB_JD_ID.DRAFT;
}
export function isScheduleJobSchedule(statusId: number = 0): boolean {
  return statusId === STATUS_WEB_JD_ID.SCHEDULE;
}

export function endingSoonPosting(endDate: Date): boolean {
  const expiringSoonFeature = getFeatureConfiguration("Candidate Sourcing Settings", "posting-expiry-notification");
  const expirinSoonValue = expiringSoonFeature?.config["expiry-notification"] || "7";
  const daysRemaining = moment.duration(moment(endDate).diff(moment())).asDays();
  return daysRemaining <= parseInt(expirinSoonValue) && daysRemaining >= 0;
}

export function getUuidFromDefaultBoard(name: string) {
  let boardToUuid = "";
  switch (name) {
    case "jobDescriptionInternalPosting":
      boardToUuid = JD_DEFAULT_BOARD_ID.INTERNAL;
      break;
    case "jobDescriptionInternalApplyFlowPosting":
      boardToUuid = JD_DEFAULT_BOARD_ID.INTERNALAPPLYFLOW;
      break;
    case "jobDescriptionExternalPosting":
      boardToUuid = JD_DEFAULT_BOARD_ID.EXTERNAL;
      break;
    case "jobDescriptionVonqPosting":
      boardToUuid = JD_DEFAULT_BOARD_ID.VONQ;
      break;
    default:
      boardToUuid = uuid();
      break;
  }
  return boardToUuid;
}
function unionJobDescriptionSchedules(tableSchedules: IScheduleJD[], defaultSchedules: IScheduleJD[], multiSchedules: IScheduleJD[]): IScheduleJD[] {
  const schedules = [] as IScheduleJD[];
  // Loop table schedules
  tableSchedules.forEach((schedule) => {
    const scheduleToSave = { ...schedule };
    scheduleToSave.startDate = getValueDate(scheduleToSave.startDate, true);
    scheduleToSave.endDate = getValueDate(scheduleToSave.endDate, true);
    schedules.push(scheduleToSave);
  });
  return [...schedules, ...defaultSchedules, ...multiSchedules];
}

export const getStatusClassByStatus = (status: string): string => {
  let statusClass = "";
  switch (status) {
    case STATUS_WEB_JD_MAPPED.DRAFT:
    case STATUS_WEB_JD_MAPPED.PAUSED:
      statusClass = "alert";
      break;
    case STATUS_WEB_JD_MAPPED.FAILED:
    case STATUS_WEB_JD_MAPPED.NOTUPDATED:
    case STATUS_WEB_JD_MAPPED.NOTDELETED:
    case STATUS_WEB_JD_MAPPED.ENDED:
      statusClass = "error";
      break;
    case STATUS_WEB_JD_MAPPED.RUNNING:
      statusClass = "success";
      break;
    case STATUS_WEB_JD_MAPPED.SCHEDULED:
    case STATUS_WEB_JD_MAPPED.PENDING:
    case STATUS_WEB_JD_MAPPED.UPDATING:
    case STATUS_WEB_JD_MAPPED.DELETING:
      statusClass = "info";
      break;
    default:
      statusClass = "noclass";
      break;
  }
  return statusClass;
};
export const getStatusNameByStatusId = (id: number, translate = false): string => {
  let status;
  switch (id) {
    case STATUS_WEB_JD_ID.DRAFT:
      status = STATUS_WEB_JD_MAPPED.DRAFT;
      break;
    case STATUS_WEB_JD_ID.SCHEDULE:
      status = STATUS_WEB_JD_MAPPED.SCHEDULED;
      break;
    case STATUS_WEB_JD_ID.FAILED:
      status = STATUS_WEB_JD_MAPPED.FAILED;
      break;
    case STATUS_WEB_JD_ID.PENDING:
      status = STATUS_WEB_JD_MAPPED.PENDING;
      break;
    case STATUS_WEB_JD_ID.PUBLISHED:
      status = STATUS_WEB_JD_MAPPED.RUNNING;
      break;
    case STATUS_WEB_JD_ID.PAUSED:
      status = STATUS_WEB_JD_MAPPED.PAUSED;
      break;
    case STATUS_WEB_JD_ID.UPDATING:
      status = STATUS_WEB_JD_MAPPED.UPDATING;
      break;
    case STATUS_WEB_JD_ID.NOTUPDATED:
      status = STATUS_WEB_JD_MAPPED.NOTUPDATED;
      break;
    case STATUS_WEB_JD_ID.DELETING:
      status = STATUS_WEB_JD_MAPPED.DELETING;
      break;
    case STATUS_WEB_JD_ID.NOTDELETED:
      status = STATUS_WEB_JD_MAPPED.NOTDELETED;
      break;
    case STATUS_WEB_JD_ID.ARCHIVED:
      status = STATUS_WEB_JD_MAPPED.ENDED;
      break;
    default:
      status = "Unkown status";

      break;
  }
  return translate ? toLiteral({ id: status }) : status;
};

export const getTooltipMessage = (id: number, statusMessage: string, translate = true): string => {
  let message;
  switch (id) {
    case STATUS_WEB_JD_ID.DRAFT:
      message = VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP.DRAFT;
      break;
    case STATUS_WEB_JD_ID.SCHEDULE:
      message = VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP.SCHEDULED;
      break;
    case STATUS_WEB_JD_ID.FAILED:
      message = VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP.FAILED1 + statusMessage + VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP.FAILED2;
      break;
    case STATUS_WEB_JD_ID.PENDING:
      message = VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP.PENDING;
      break;
    case STATUS_WEB_JD_ID.PUBLISHED:
      message = VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP.RUNNING;
      break;
    case STATUS_WEB_JD_ID.UPDATING:
      message = VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP.UPDATING;
      break;
    case STATUS_WEB_JD_ID.NOTUPDATED:
      message = VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP.NOTUPDATED1 + statusMessage + VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP.NOTUPDATED2;
      break;
    case STATUS_WEB_JD_ID.DELETING:
      message = VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP.DELETING;
      break;
    case STATUS_WEB_JD_ID.NOTDELETED:
      message = VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP.NOTDELETED1 + statusMessage + VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP.NOTEDELETED2;
      break;
    case STATUS_WEB_JD_ID.ARCHIVED:
      message = VONQ_JOBPOST_STATUS_EXLAINATION_TOOLTIP.ENDED;
      break;
    default:
      message = "Unkown status";

      break;
  }
  return translate ? toLiteral({ id: message }) : message;
};

export const generateDefaultSchedule = (boardId: string): IScheduleJD => {
  return {
    id: uuid(),
    statusId: STATUS_WEB_JD_ID.DRAFT,
    boardId: boardId,
    customFields: {},
    startDate: null,
    endDate: null,
  };
};

/***************************************************** CART - COST ********************************************************/
export const getBudgetCurrentPeriod = (startDate: string, budgets: any, isForRemaining: boolean = false) => {
  //Get current budget for branch and startDate
  const branchId = getBranchId();

  const momentDate = startDate === "" ? moment() : moment(startDate, getDateFormatFromTenantOrBrowser());
  const startDateYear = momentDate.year();
  const datePeriod = getDatePeriod(momentDate.format(getDateFormatFromTenantOrBrowser()))?.period;

  const budget = budgets?.find((bg: any) => bg.branchId === branchId && bg.year === startDateYear);

  if (isForRemaining) {
    return budget?.[`period_${datePeriod}`] - budget?.[`period_${datePeriod}_expend`] || 0;
  }
  return budget?.[`period_${datePeriod}`] || 0;
};
export const getDatePeriod = (date: any) => {
  const budgetPeriod = getFeatureConfigurationValues("General Settings", "sourcing-budget");
  let momentDate: any = {};

  if (moment(date, getDateFormatFromTenantOrBrowser()).isValid()) {
    momentDate = moment(date, getDateFormatFromTenantOrBrowser());
  } else {
    momentDate = date === "" ? moment() : moment(date);
  }

  let resultObj: any = {
    period: 1,
    label: "Not found",
    moment: moment(),
  };
  if (budgetPeriod?.budgetPeriod === PeriodType.quarter) {
    resultObj = {
      period: momentDate.quarter(),
      label: `${toLiteral({ id: "Quarter" })} ${momentDate.quarter()}/${momentDate.year()} `,
      moment: momentDate,
    };
  } else if (budgetPeriod?.budgetPeriod === PeriodType.month) {
    resultObj = {
      period: momentDate.month() + 1,
      label: `${toLiteral({ id: "Month" })} ${momentDate.month() + 1}/${momentDate.year()} `,
      moment: momentDate,
    };
  }
  return resultObj;
};
export const getExpendedBudgetPeriod = (startDate: any, budgets: any[]) => {
  const branchId = getBranchId();
  const momentDate = startDate === "" ? moment() : moment(startDate, getDateFormatFromTenantOrBrowser());
  const startDateYear = momentDate.year();

  const budget = budgets?.find((bg: any) => bg.branchId === branchId && bg.year === startDateYear);
  const currentStartDatePeriod: number = getDatePeriod(momentDate.format(getDateFormatFromTenantOrBrowser()))?.period;

  return budget?.[`period_${currentStartDatePeriod}_expend`] || 0;
};
export const generateItemToCart = (item: any, joveoBudget: number) => {
  return {
    statusId: parseInt(item?.statusId),
    startDate: item?.startDate,
    endDate: item?.endDate,
    jobDescriptionBoard: {
      name: JOVEO_TITLE_CART,
      cost: joveoBudget,
    },
  };
};
/*************************************************** END CART - COST ******************************************************/

export const checkOverWriteApplyURL = (values: any) => {
  const applicationMethod = getFeatureConfiguration("General Settings", "job-apply-method");
  // Check apply method is url autogenerated
  if (applicationMethod?.isEnabled) {
    switch (applicationMethod?.config?.applyMethod) {
      case JobApplyMethod.URLAutogeneration:
        const overwriteSetting = getFeatureConfiguration("Candidate Sourcing Settings", "overwrite-apply-url");
        // If it is enable
        if (overwriteSetting?.isEnabled) {
          // Get parts
          const { baseUrl, notModificable } = overwriteSetting?.config || {};
          let textToConcat = "";
          if (notModificable === OVERWRITE_APPLY.JOBDESCRIPTIONID) {
            textToConcat = values?.id ? values?.id : "";
          } else {
            textToConcat = values?.dataVacancyReferenceNumber ? values?.dataVacancyReferenceNumber : "";
          }
          // Encode and concat
          textToConcat = encodeURIComponent(textToConcat);
          values.dataApplyURL = `${baseUrl}${textToConcat}`;
        }
        break;
      case JobApplyMethod.quickApplyPage:
        values.dataApplyURL = `${window.location.origin}/quick-apply/${store?.getState()?.tenant?.selectedTenant?.alias}/${values?.id || ""}`;
        break;
      case JobApplyMethod.directInRC:
        values.dataApplyURL = `${window.location.origin}/process/${store?.getState()?.tenant?.selectedTenant?.alias}/${values?.processId || ""}`;
        break;
      case JobApplyMethod.externalApplyPage:
        if (values.dataApplyURL && values.dataApplyURL.indexOf(INFO_JD_PARAM) === -1) {
          const characterToContact = values.dataApplyURL.indexOf("?") === -1 ? "?" : "&";
          values.dataApplyURL = `${values.dataApplyURL}${characterToContact}${INFO_JD_PARAM}=${values.id}`;
        }
        break;
      case JobApplyMethod.standaloneApplyPage:
      default:
        break;
    }
  }
  return values;
};
export const checkOverWriteClientId = (values: any, clients: any[]) => {
  const { dataClientId, dataClientName } = values;
  if (!dataClientId && dataClientName) {
    const client = clients?.find((_client: any) => _client.name === dataClientName);
    values.dataClientId = `${client ? client.id : ""}`;
  }
  return values;
};

export const checkScheduleIsEmpty = (schedule: any, scheduleGroup: string = "") => {
  let isValid = true;
  if (!schedule?.[scheduleGroup]?.startDate || !schedule?.[scheduleGroup]?.endDate) {
    isValid = false;
  } else if (schedule?.[scheduleGroup]?.schedules?.length > 0) {
    schedule?.[scheduleGroup]?.schedules?.forEach((sched: any) => {
      if (!sched?.startDate || !sched?.endDate) isValid = false;
    });
  }
  return isValid;
};

export const getClassNameByStatusId = (id: number) => {
  return id === STATUS_WEB_JD_ID.DRAFT
    ? "draft"
    : id === STATUS_WEB_JD_ID.SCHEDULE
    ? "Schedule"
    : id === STATUS_WEB_JD_ID.FAILED
    ? "Failed"
    : id === STATUS_WEB_JD_ID.PENDING
    ? "Pending"
    : id === STATUS_WEB_JD_ID.PUBLISHED
    ? "Published"
    : id === STATUS_WEB_JD_ID.PAUSED
    ? "Paused"
    : id === STATUS_WEB_JD_ID.UPDATING
    ? "Updating"
    : id === STATUS_WEB_JD_ID.NOTUPDATED
    ? "Notupdated"
    : id === STATUS_WEB_JD_ID.DELETING
    ? "Deleting"
    : id === STATUS_WEB_JD_ID.NOTDELETED
    ? "Notdeleted"
    : id === STATUS_WEB_JD_ID.ARCHIVED
    ? "archived"
    : "draft";
};

export const getClassNameByStatusIdUI = (id: number) => {
  return id === STATUS_WEB_JD_ID.DRAFT
    ? "alert"
    : id === STATUS_WEB_JD_ID.SCHEDULE
    ? "success"
    : id === STATUS_WEB_JD_ID.FAILED
    ? "error"
    : id === STATUS_WEB_JD_ID.PENDING
    ? "alert"
    : id === STATUS_WEB_JD_ID.PUBLISHED
    ? "info"
    : id === STATUS_WEB_JD_ID.PAUSED
    ? "info"
    : id === STATUS_WEB_JD_ID.UPDATING
    ? "alert"
    : id === STATUS_WEB_JD_ID.NOTUPDATED
    ? "error"
    : id === STATUS_WEB_JD_ID.DELETING
    ? "info"
    : id === STATUS_WEB_JD_ID.NOTDELETED
    ? "error"
    : id === STATUS_WEB_JD_ID.ARCHIVED
    ? "error"
    : "alert";
};

export const checkPastDatesSchedules = (jobDescriptionSchedules: any[] = []) => {
  let isValid = true;
  const today = moment.utc().startOf("day").format("x");
  jobDescriptionSchedules.forEach((jobDescriptionSchedule) => {
    if (jobDescriptionSchedule.statusId === STATUS_WEB_JD_ID.DRAFT) {
      // Format date to timestamp
      let startDate = jobDescriptionSchedule?.startDate?.toString();
      if (startDate.match(/\D/g)) startDate = getValueDate(startDate, true);
      // Check if it is in the past
      if (startDate && today > startDate) {
        isValid = false;
      }
    }
  });
  return isValid;
};

export function getJobDescriptionContactUser() {
  const tenantStore = store.getState()?.tenant;
  return {
    email: tenantStore?.userEmail || "",
    name: tenantStore?.userName || "",
    phone: tenantStore?.userPhone || "",
  };
}

export function getJobDescriptionContactBranch() {
  const selectedTenantStore = store.getState()?.tenant.selectedTenant;
  const branchId = selectedTenantStore.branchId;
  const branches = selectedTenantStore.branches;
  const currentBranch = branches.find((branch: any) => branch.branchId === branchId);
  return {
    email: currentBranch?.branchEmail || "",
    name: currentBranch?.branchName || "",
    phone: currentBranch?.branchPhone || "",
  };
}

export function getAllSchedules(values: any = {}) {
  let schedules = _.cloneDeep(values.jobDescriptionSchedules || []);
  if (values.jobDescriptionInternalPosting && Object.keys(values.jobDescriptionInternalPosting).length !== 0) {
    const internalSchedules = values.jobDescriptionInternalPosting || {};
    const { id, statusId, startDate, endDate } = internalSchedules;
    schedules.push({ id, statusId, startDate, endDate });
    if (internalSchedules.schedules && internalSchedules.schedules.length > 0) schedules = schedules.concat(internalSchedules.schedules);
  }
  if (values.jobDescriptionInternalApplyFlowPosting && Object.keys(values.jobDescriptionInternalApplyFlowPosting).length !== 0) {
    const internalApplyFlowSchedules = values.jobDescriptionInternalApplyFlowPosting || {};
    const { id, statusId, startDate, endDate } = internalApplyFlowSchedules;
    schedules.push({ id, statusId, startDate, endDate });
    if (internalApplyFlowSchedules.schedules && internalApplyFlowSchedules.schedules.length > 0) schedules = schedules.concat(internalApplyFlowSchedules.schedules);
  }
  if (values.jobDescriptionExternalPosting && Object.keys(values.jobDescriptionExternalPosting).length !== 0) {
    const externalSchedules = values.jobDescriptionExternalPosting || {};
    const { id, statusId, startDate, endDate } = externalSchedules;
    schedules.push({ id, statusId, startDate, endDate });
    if (externalSchedules.schedules && externalSchedules.schedules.length > 0) schedules = schedules.concat(externalSchedules.schedules);
  }
  return schedules;
}

export const checkAdminAddSections = (values: any): boolean => {
  return !values?.descriptionTemplate && isAdminOrSuperAdmin();
};

export const defaultValueCustomFieldBoards = (values: any): any => {
  // Here the conditions for the tenant / board
  return {
    dataSalaryMin: values.dataSalaryMin,
    dataSalaryMax: values.dataSalaryMax,
    dataYearsOfExperience: values.dataYearsOfExperience,
  };
};

export const getIndustryLabel = (industryVal: string) => {
  return industryJson.find((industry: any) => industry?.value === industryVal)?.label || "";
};

export const getSubindustryLabel = (subindustryVal: string) => {
  return subindustryJson.find((industry: any) => industry?.value === subindustryVal)?.label || "";
};
export const getProfessionLabel = (industryVal: string) => {
  return professionJson.find((industry: any) => industry?.value === industryVal)?.label || "";
};

export const getSubprofessionLabel = (subindustryVal: string) => {
  return subprofessionJson.find((industry: any) => industry?.value === subindustryVal)?.label || "";
};

export const isStatusActive = (statusId: number) => {
  return (
    statusId === STATUS_WEB_JD_ID.PENDING ||
    statusId === STATUS_WEB_JD_ID.PUBLISHED ||
    statusId === STATUS_WEB_JD_ID.PAUSED ||
    statusId === STATUS_WEB_JD_ID.UPDATING ||
    statusId === STATUS_WEB_JD_ID.NOTUPDATED ||
    statusId === STATUS_WEB_JD_ID.DELETING
  );
};

export const filtersResultsByUserBranch = (results: any[], userBranches: any[] = []) => {
  return results?.filter((item: any) => {
    return userBranches?.findIndex((branch: any) => branch?.branchId === item?.branchId) === -1 ? false : true;
  });
};

export function paintFilters(filters: any, jobSourcingPageType: string) {
  if (jobSourcingPageType === "post") {
    return filters.map((element: any, index: number) => (
      <option key={index} value={element.name}>
        {toLiteral({ id: element.title })}
      </option>
    ));
  } else {
    // Render the options based on col.jobFilterOptions
    return (
      filters?.map((option: any) => {
        const value = typeof option === "object" ? option.value : option;
        const label = typeof option === "object" ? option.label : option;
        return (
          <option key={value} value={value}>
            {label}
          </option>
        );
      }) || []
    );
  }
}
